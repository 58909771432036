import React from "react";
import "./App.css";
import { useEffect, useRef } from "react";

function Base() {
  useEffect(() => {
    var nav = document.querySelector("nav");

    window.addEventListener("scroll", function () {
      if (window.pageYOffset > 50) {
        nav.classList.add("bg-white", "border-color");
      } else {
        nav.classList.remove("bg-white", "border-color");
      }
    });
  });

  return (
    <div>
      <section className="header" id="Barra-de-navegacion">
        <section>
          <div className="navbar-edit">
            <nav className=" navbar navbar-expand-lg fixed-top p-md-3 fs-5 fw-semibold">
              <div className="container container-fluid">
                <a className="navbar-brand" href="#">
                  <img
                    src="../images/jpg/Logo-principal.png"
                    alt=""
                    width={60}></img>
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation">
                  <span className="navbar-toggler-icon "></span>
                </button>
                <div className="collapse navbar-collapse mt-2" id="navbarNav">
                  <div className=" mx-auto container-fluid"></div>

                  <ul className="navbar-nav tetx-danger">
                    <li className="nav-item mx-2">
                      <a className="nav-link" href="#">
                        Inicio
                      </a>
                    </li>
                    <li className="nav-item mx-2">
                      <a className="nav-link" href="#servicios">
                        Servicios
                      </a>
                    </li>
                    <li className="nav-item mx-2">
                      <a className="nav-link" href="#separador2">
                        Contacto
                      </a>
                    </li>
                    <li className="nav-item mx-2 ">
                      <a className="nav-link" href="#">
                        Nosotros
                      </a>
                    </li>
                    <li className="nav-item btn-llamar ms-4 m-sm-auto">
                      <a
                        className="nav-link fs-5 fw-lighter"
                        id="llamar"
                        href="#separador2">
                        Llámanos
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </section>

        <div className="d-flex align-items-center contenedor-text tamano">
          <div className="container container-fluid ">
            <div className="row">
              <div className="col-sm-8"></div>

              <p className="mb-3 text-1 fs-1">
                {" "}
                Bienvenido a <br></br>
                <span className="text-move">Beacons IoT Technologies</span>
              </p>

              <p className="mt-3 text-3 fs-3">
                "Hacemos que las cosas funcionen"
              </p>

              <h2 className="text-4">
                Desarrollo de tecnología{" "}
                <span className="text-iot fs-1">IoT</span>{" "}
              </h2>

              <p className="w-100 fs-5 text-5 fw-light">
                Utilizamos la tecnología Beacons ble para el <br /> desarrollo
                de aplicaciones IoT (Internet of things). <br />
                Con nuestra plataforma (Enlace Azul) <br />
                sabrás dónde ubicar tus activos en tiempo real.
              </p>
            </div>
          </div>
        </div>

        <div id="separador1" className="container-fluid mt-5">
          <div id="expertos" className="container w-50 ps-5 rounded">
            <div className="row align-items-center">
              <div className="col-sm p-3">
                <img
                  src="/images/png/bg_3.png"
                  alt="IoT"
                  className="w-75 mx-auto d-block"
                />
              </div>

              <div className="col-sm p-3 text-center">
                <p id="somos" className="h3">
                  Somos expertos en
                </p>
                <h4 id="tech" className=" h2 text-shadow">
                  Tecnologia
                </h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div id="servicios">
          <div className="container-md p-5">
            <div className="row pt-4">
              <h3 className="text-center pb-4 pt-3 h1 ">
                Nuestros <span id="text-ser">Servicios</span>{" "}
              </h3>
            </div>

            <div className="row">
              <div className="col-sm">
                <div
                  id="caed1"
                  className="card w-100 card-border mb-5 w-50 mx-auto">
                  <img
                    src="/images/png/salud.png"
                    className="card-img-top"
                    alt="Cartas"
                  />
                  <div className="card-body">
                    <h5 className="card-title text-center">
                      <a>Enlace Azul</a>
                      <br></br>
                      <a href="/enlaceazul" className="fs-6 mt-2">
                        Ver más
                      </a>
                    </h5>
                    <p className="card-text">
                      Plataforma que utiliza la tecnología Beacons o balizas
                      informativas, para ubicar tus activos en tiempo real.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm">
                <div
                  id="caed2"
                  className="card w-100 card-border mb-5 w-50 mx-auto">
                  <img
                    src="/images/png/tg_1.png"
                    className="card-img-top"
                    alt="Cartas"
                  />
                  <div className="card-body">
                    <h5 className="card-title text-center">
                      <a>Ingenieria de datos</a>
                      <br></br>
                      <a
                        href=""
                        className="fs-6 mt-2 text-dark text-decoration-none puntero">
                        Ver más
                      </a>
                    </h5>
                    <p className="card-text">
                      Hacemos que sus datos sean un recurso valioso para su
                      empresa a través del analítica computacional.
                    </p>
                  </div>
                </div>
              </div>

              <div className="col-sm">
                <div
                  id="caed3"
                  className="card w-100 card-border mb-5 w-50 mx-auto">
                  <img
                    src="/images/png/tg_2.png"
                    className="card-img-top"
                    alt="Cartas"
                  />
                  <div className="card-body">
                    <h5 className="card-title text-center">
                      <a>Apps y páginas web</a>
                      <br></br>
                      <a
                        href=""
                        className="fs-6 mt-2 text-dark text-decoration-none puntero">
                        Ver más
                      </a>
                    </h5>
                    <p className="card-text">
                      Realizamos desarrollo de páginas y aplicaciones web de
                      acuerdo a las necesidades de su empresa.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div id="separador2" className="content bg-primary mt-4">
          <div className="text-center pt-4">
            <h3>! No dudes en contactarnos !</h3>
            <h3>Estamos ubicados en Medellín, Colombia</h3>
          </div>
        </div>
      </section>

      <section id="navContacto">
        <div className="container w-100 mt-5">
          <h1 className="text-center mb-5">
            <span id="contact">Contáctanos</span>{" "}
            <span id="through">mediante</span>{" "}
          </h1>
        </div>

        <div className="row container mx-auto w-auto">
          <div className="col-sm">
            <article id="social">
              <p className="">
                Para tener mayor información de nuestros servicios y una mejor
                asesoría, comunicate con nosotros a travéz de:
              </p>

              <div className="container mt-4">
                <div className="d-flex text-center justify-content-around w-auto">
                  <a
                    id="whatsapp"
                    href="https://wa.me/573146148505"
                    target="_blank"
                    title="Chatear por WhatsApp">
                    <img
                      src="/images/svg/whatsapp.svg"
                      alt="WhatsApp"
                      width="50px"
                    />
                  </a>

                  <a
                    id="linkedin"
                    href="https://www.linkedin.com/in/beacons-iot-technologies-422b16271"
                    target="_blanck"
                    title="Perfil de LinkedIn">
                    <img
                      src="/images/svg/linkedin.svg"
                      alt="LinkedIn"
                      width="50px"
                    />
                  </a>

                  <a href="#">
                    <img src="/images/svg/gmail.svg" alt="Gmail" width="50px" />
                  </a>

                  <a href="#">
                    <img
                      src="/images/svg/instagram.svg"
                      alt="Instagram"
                      width="50px"
                    />
                  </a>
                </div>
              </div>

              <div className="container d-flex justify-content-start align-items-center mt-4">
                <img
                  src="/images/svg/celular.svg"
                  alt=""
                  className="telefono"
                />
                <p className="ms-3 mt-3 fw-normal w-100">(+57) 3146148505</p>
              </div>

              <div className="container d-flex justify-content-start align-items-center mt-4">
                <img
                  src="/images/svg/mensaje.svg"
                  alt=""
                  className="telefono"
                />
                <p className="ms-3 mt-3 fw-normal  w-100">
                  ventas@beaconsiottechnologies.com
                </p>
              </div>

              <div className="container d-flex justify-content-start align-items-center mt-4">
                <img
                  src="/images/svg/direccion.svg"
                  alt=""
                  className="telefono"
                />
                <p className="ms-3 mt-3 fw-normal w-100">
                  {" "}
                  Calle 65e #91a-53, Medellín.{" "}
                </p>
              </div>
            </article>
          </div>
          <div className="col">
            <article id="form">
              <div className="container">
                <div className=" w-auto">
                  <div className="mb-3">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label inputs">
                      Nombre
                    </label>
                    <input
                      type=""
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="Carlos Arteaga"
                    />
                  </div>

                  <div className="mb-3">
                    <label
                      for="exampleFormControlInput1"
                      className="form-label">
                      Correo electronico
                    </label>
                    <input
                      type="email"
                      className="form-control"
                      id="exampleFormControlInput1"
                      placeholder="email@bit.com"
                    />
                  </div>

                  <div className="mb-3">
                    <label
                      for="exampleFormControlTextarea1"
                      className="form-label">
                      Mensaje
                    </label>
                    <textarea
                      className="form-control"
                      id="exampleFormControlTextarea1"
                      rows="3"></textarea>
                  </div>

                  <div className="d-flex justify-content-center mx-auto mt-4">
                    <button
                      id="send"
                      type="submit"
                      className="btn text-center fs-5">
                      Enviar mensaje
                    </button>
                  </div>
                </div>
              </div>
            </article>
          </div>
        </div>
      </section>

      <section>
        <div
          id="footer"
          className="content bg-primary mt-5 d-flex align-items-center justify-content-center">
          <div className="text-center ">
            <h3>&copy; Beacons IoT Technologies</h3>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Base;
