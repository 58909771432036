import React from "react";
import "./App.css";
import { useEffect, useRef } from "react";

function Azul() {
  useEffect(() => {
    var nav = document.querySelector("nav");

    window.addEventListener("scroll", function () {
      if (window.pageYOffset > 50) {
        nav.classList.add("bg-white", "border-color");
      } else {
        nav.classList.remove("bg-white", "border-color");
      }
    });
  });

  return (
    <div>
      <section className="header" id="Barra-de-navegacion">
        <div className="navbar-edit">
          <nav className=" navbar navbar-expand-lg fixed-top p-md-3 fs-5 fw-semibold">
            <div className="container container-fluid">
              <a className="navbar-brand" href="#">
                <img
                  src="../images/jpg/Logo-principal.png"
                  alt=""
                  width={60}></img>
              </a>
              <button
                className="navbar-toggler"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation">
                <span className="navbar-toggler-icon "></span>
              </button>
              <div className="collapse navbar-collapse mt-2" id="navbarNav">
                <div className=" mx-auto container-fluid"></div>

                <ul className="navbar-nav tetx-danger">
                  <li className="nav-item mx-2">
                    <a className="nav-link" href="#">
                      Inicio
                    </a>
                  </li>
                  <li className="nav-item mx-2">
                    <a className="nav-link" href="#servicios">
                      Información
                    </a>
                  </li>
                  <li className="nav-item btn-llamar ms-4 m-sm-auto">
                    <a
                      className="nav-link fs-5 fw-lighter"
                      id="llamar"
                      href="/">
                      Volver
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </nav>
        </div>

        <div className="d-flex align-items-center contenedor-text tamano">
          <div className="container container-fluid ">
            <div className="row">
              <div className="col-sm-8"></div>

              <p className="mb-3 text-1 fs-1">
                Beacons IoT Technologies <br></br>
                <span className="texto text-2 text-move">Enlace azul</span>
              </p>

              <h2 className="text-4">Plataforma para escaneo de activos</h2>

              <p className="w-100 fs-5 text-5 fw-light">
                Contamos con tecnología de punta <br /> para el monitoreo de tus
                activos, <br />
                visita nuestra Plataforma www.iotenlaceazul.com .
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div id="servicios">
          <div className="container-md p-2">
            <div className="row pt-4">
              <h3 className="text-center pb-5 h1 ">
                Escanea tus activos en{" "}
                <span
                  id="text-ser"
                  className="m-xxl-0 m-xl-0 m-lg-0 m-md-0 m-sm-0 ms-3">
                  tiempo real
                </span>{" "}
              </h3>
            </div>

            <div className="row">
              <div className="col-sm mt-3">
                <div
                  id="card1"
                  className="card w-100 card-border mb-5 w-50 mx-auto">
                  <div className="card-body text-center">
                    <h5 className="card-title text-center">Introducción</h5>
                    <iframe
                      width="auto"
                      height="auto"
                      src="https://www.youtube.com/embed/iF62wWcTATw?si=2KMpydL8DUapEWlx"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen></iframe>
                  </div>
                </div>
              </div>

              <div className="col-sm mt-3">
                <div
                  id="card2"
                  className="card w-100 card-border mb-5 w-50 mx-auto">
                  <div className="card-body text-center">
                    <h5 className="card-title text-center">Beneficios</h5>
                    <iframe
                      width="auto"
                      height="auto"
                      src="https://www.youtube.com/embed/fZaQxLZl8SU?si=XBUAOjxOH4WoIeJ5"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen></iframe>
                  </div>
                </div>
              </div>

              <div className="col-sm mt-3">
                <div
                  id="card3"
                  className="card w-100 card-border mb-5 w-50 mx-auto">
                  <div className="card-body text-center">
                    <h5 className="card-title text-center">Demostración</h5>
                    <iframe
                      width="auto"
                      height="auto"
                      src="https://www.youtube.com/embed/KnSazD99yus?si=opzaiMd9qJD1Tkl3"
                      title="YouTube video player"
                      frameborder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                      allowfullscreen></iframe>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="container-md p-1">
          <div className="row">
            <div className="col-sm mt-3">
              <div
                id="card1"
                className="card w-100 card-border mb-3 w-50 mx-auto">
                <div className="card-body">
                  <h5 className="card-title text-center">Sector salud</h5>
                  <img
                    src="../images/jpg/salud.png"
                    className="card-img-top "
                    alt="Cartas"></img>
                </div>
              </div>
            </div>

            <div className="col-sm mt-3">
              <div
                id="card2"
                className="card w-100 card-border mb-3 w-50 mx-auto">
                <div className="card-body">
                  <h5 className="card-title text-center">Sector industrial</h5>
                  <img
                    src="../images/jpg/Industrial.png"
                    className="card-img-top "
                    alt="Cartas"></img>
                </div>
              </div>
            </div>

            <div className="col-sm mt-3">
              <div
                id="card3"
                className="card w-100 card-border mb-3 w-50 mx-auto">
                <div className="card-body">
                  <h5 className="card-title text-center">Beneficios</h5>
                  <img
                    src="../images/jpg/beneficios.png"
                    className="card-img-top "
                    alt="Cartas"></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div
          id="footer"
          className="content bg-primary mt-5 d-flex align-items-center justify-content-center">
          <div className="text-center ">
            <h3>&copy; Beacons IoT Technologies</h3>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Azul;
